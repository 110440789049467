import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import localForage from "localforage";
import React, { useEffect, useState } from "react";
import { CalendarCard } from "./components/CalendarCard";
import { data } from "./data";
import { shuffle } from "./helper/Shuffle";
import { PresentModel } from "./models/PresentModel";

export function App(): JSX.Element {
    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                flexGrow: 1,
                margin: 20,
            },
        })
    );
    const classes = useStyles();

    const [presents, setPresents] = useState<PresentModel[]>([]);

    useEffect(() => {
        async function getData(): Promise<void> {
            const cachedAtDay: number | null = await localForage.getItem("cachedAtDay");
            if (!cachedAtDay) {
                localForage.setItem("presents", data);
                localForage.setItem("cachedAtDay", new Date().getDate());
                setPresents(data);
                return;
            }

            // Check if cache is from yesterday
            let getFromCache: boolean = false;
            if (typeof cachedAtDay === "number") {
                const today: number = new Date().getDate();
                if (today === cachedAtDay) {
                    getFromCache = true;
                }
            }

            if (getFromCache === true) {
                let cachedData: PresentModel[] | null = await localForage.getItem("presents");
                if (cachedData) {
                    setPresents(cachedData);
                    return;
                }
            }

            localForage.setItem("presents", data);
            localForage.setItem("cachedAtDay", new Date().getDate());
            setPresents(data);
        }

        getData();
    }, []);

    if (!presents || presents.length === 0) {
        return <CircularProgress />;
    }

    const flipCards: JSX.Element[] = presents.map((present: PresentModel) => {
        return (
            <Grid item xs={6} sm={4} md={3} lg={2} key={"react-key-" + present.day}>
                <CalendarCard present={present} isFlipped={present.isRevealed} />
            </Grid>
        );
    });

    const shuffledCards: JSX.Element[] = shuffle(flipCards);

    return (
        <div className={classes.root}>
            <Grid justifyContent="center" container spacing={3}>
                {shuffledCards}
            </Grid>
        </div>
    );
}
