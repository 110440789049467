import { PresentModel } from "./models/PresentModel";

export const data: PresentModel[] = [
    {
        day: 1,
        // Cola
        text: "Gibt ein kleines Geschenkchen zu Beginn.",
        isRevealed: false,
        color: "#f06292",
    },
    {
        day: 2,
        text: "Futzmassage!",
        isRevealed: false,
        color: "#7b1fa2",
    },
    {
        day: 3,
        text: "1 Massage mit Öl! :O",
        isRevealed: false,
        color: "#3f51b5",
    },
    {
        day: 4,
        text: "1 Kopfmassage",
        isRevealed: false,
        color: "#00796b",
    },
    {
        day: 5,
        // Schokoladendrink
        text: "1 kleine heiße Aufmerksamkeit.",
        isRevealed: false,
        color: "#388e3c",
    },
    {
        day: 6,
        text: "Gutschein: Wir schauen einen Film deiner Wahl!",
        isRevealed: false,
        color: "#ff5722",
    },
    {
        day: 7,
        text: "Ein Gutschein für einmal kochen für dich!",
        isRevealed: false,
        color: "#ef5350",
    },
    {
        day: 8,
        // Duftkerzen
        text: "Heute Abend gibts ein kleines Geschenk.",
        isRevealed: false,
        color: "#a1887f",
    },
    {
        day: 9,
        text: "Futzmassage die Zweite!!",
        isRevealed: false,
        color: "#ba68c8",
    },
    {
        day: 10,
        text: "Ich weiß, du liebst es. Ölmassage!!",
        isRevealed: false,
        color: "#bf360c",
    },
    {
        day: 11,
        text: "Heute eins kleines Massage beim Gucki vorm Fernseher.",
        isRevealed: false,
        color: "#817373",
    },
    {
        day: 12,
        text: "Gutschein: Wir schauen einen Film deiner Wahl!",
        isRevealed: false,
        color: "#f44336",
    },
    {
        day: 13,
        // Hitschler
        text: "Heute Abend gibts ein kleines Geschenkchen!",
        isRevealed: false,
        color: "#3f51b5",
    },
    {
        day: 14,
        text: "Fußmassage!!",
        isRevealed: false,
        color: "#f06292",
    },
    {
        day: 15,
        text: "Ein Gutschein für einmal kochen für dich!",
        isRevealed: false,
        color: "#448aff",
    },
    {
        day: 16,
        text: "Gutschein für ein Lullispiel deiner Wahl und ich bin voll dabei (Versuchs)!",
        isRevealed: false,
        color: "#7986cb",
    },
    {
        day: 17,
        text: "Kopfmassage UND Fußmassage. Wahnsinn!",
        isRevealed: false,
        color: "#1f6caa",
    },
    {
        day: 18,
        text: "Es isch mal wieder Zeit für 1 Ölmassage!",
        isRevealed: false,
        color: "#f4511e",
    },
    {
        day: 19,
        text: "Heute Abend gibts eins kleine Aufmerksamkeit!",
        isRevealed: false,
        color: "#009688",
    },
    {
        day: 20,
        text: "Heute darfst du dir was aussuchen, dass ich dir gutes tun darf. <3",
        isRevealed: false,
        color: "#9575cd",
    },
    {
        day: 21,
        text: "Fußmassage die Letzte",
        isRevealed: false,
        color: "#757575",
    },
    {
        day: 22,
        text: "Massage vor dem Fernseher während wir was glotzen, was du willst.",
        isRevealed: false,
        color: "#00bcd4",
    },
    {
        day: 23,
        text: "Ölmassage, die Letzte",
        isRevealed: false,
        color: "#43a047",
    },
    {
        day: 24,
        text: "Frohe Weihnachten! Ich liebe dich mein Schatzi. <3",
        isRevealed: false,
        color: "#0288d1",
    },
];
