import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import localforage from "localforage";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { PresentModel } from "../models/PresentModel";

interface CalendarCardProps {
    present: PresentModel;
    isFlipped: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            cursor: "pointer",
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
            height: 150,
            lineHeight: "150px",
            fontSize: 20,
        },
        cardText: {
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: "normal",
        },
        day: {
            fontSize: 28,
        },
    })
);

export function CalendarCard(props: CalendarCardProps) {
    const { present } = props;
    const [isFlipped, setIsFlipped] = useState(props.isFlipped);
    const [showNotAllowedText, setShowNotAllowedText] = useState(false);
    const classes = useStyles();

    function checkIfCanFlip(): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const currentYear: number = today.getFullYear();

        const dateOfCard = new Date(currentYear, 11, present.day);

        if (dateOfCard <= today) {
            return true;
        }
        return false;
    }

    async function onClickCard() {
        if (checkIfCanFlip() === false) {
            setShowNotAllowedText(true);
        } else {
            // Mark as turned
            const allData: PresentModel[] | null = await localforage.getItem("presents");
            if (!allData) {
                console.error("I sucked. Fehler beim Daten lesen im on click card event.");
                return;
            }

            const clickedPresent: PresentModel | undefined = allData.find(
                (currentPresent) => currentPresent.day === present.day
            );
            if (!clickedPresent) {
                console.error("I sucked. Could not find clicked card.");
                return;
            }

            clickedPresent.isRevealed = true;

            // Write back by overwriting everything because I can
            localforage.setItem("presents", allData);
        }

        setIsFlipped(true);
    }

    const backPaper: JSX.Element = showNotAllowedText ? (
        <Paper elevation={3} className={classes.paper}>
            <span className={classes.cardText}>
                <span>Möööp.</span>
                <br />
                <span>Des darfst du doch noch nicht, du Sützi!</span>
            </span>
        </Paper>
    ) : (
        <Paper
            style={{ backgroundColor: present.color, color: "white" }}
            elevation={3}
            className={classes.paper}
        >
            <span className={classes.cardText}>{present.text}</span>
        </Paper>
    );

    return (
        <ReactCardFlip flipSpeedFrontToBack={3} isFlipped={isFlipped}>
            <Paper onClick={() => onClickCard()} elevation={3} className={classes.paper}>
                <span className={classes.cardText + " " + classes.day}>{present.day}</span>
            </Paper>
            {backPaper}
        </ReactCardFlip>
    );
}
